

export const accordionData = [
    {
        "title": "Who runs Travelway?",
        "content": "Travelway is driven by a passionate team dedicated to enhancing your \
                     commuting experience. We're here to make your journeys in Lagos, Nigeria, \
                      seamless and enjoyable."
    },
     {
        "title": "Why do you only operate in Lagos?",
        "content": "We've chosen to focus on Lagos to provide specialized services and ensure \
         our platform caters perfectly to the unique needs of this vibrant city."
    },
     {
        "title": "How do you I get the app?",
        "content": "Getting the app is a breeze! Simply visit your device's app store, search for \
         \"Travelway,\"  and hit download. Your ticket to convenient commuting is just a click away."
    },
     {
        "title": "How do you I sign up?",
        "content": "Signing up is quick and straightforward. Open the app, click on \"Sign Up,\" fill \
         in your details, and you're good to go! Start your stress-free journeys with Travelway effortlessly."
    },
     {
        "title": " Can't find what you're looking for?",
        "content": "No problem! If your question isn't covered here, reach out to our support  team. \
         We are here to assist you promptly and ensure your satisfaction. Your journey with Travelway matters to us"
    },
     {
        "title": " How do I book a ride and unlock stress-free travel?",
        "content": "Embark on a journey of ease! Once you've joined the Travelway family,\
         dive into the app,  pick your route, choose your ride, and let the adventure begin.\
         Every booking unlocks a world of seamless experiences."
    },
     {
        "title": "Are there loyalty rewards?",
        "content": "Absolutely! Elevate your ride, earn exclusive rewards, and let each journey \
         pamper you with perks and benefits. Your loyalty not only takes you places but adds a touch \
        of luxury to every Travelway experience."
    },
    {
        "title": "What payment methods do you accept for a smooth ride?",
        "content": "Select your preferred payment method—be it cards or mobile payments—and glide \
           effortlessly through the city. Your smooth transactions pave the way for even smoother rides."
    },
    {
        "title": "Is there 24/7 customer support to cater to your every need?",
        "content": "Absolutely! Our support team is your constant companion, ready to turn every question \
        into a solution. Reach out anytime, and let your journey be guided by the warmth of our support."
    },
    {
        "title": "Can I schedule rides in advance and embrace the future?",
        "content": "Absolutely! Plan your days ahead with our scheduling feature. Schedule rides, \
         embrace convenience, and let Travelway turn your future journeys into a well-orchestrated \
         symphony of seamless travel."
    },
    {
        "title": " How do I update my account information and personalize your journey?",
        "content": "Your details, your journey! Keep your information updated effortlessly. Dive into your profile settings, \
          edit away, and let your account mirror the evolving chapters of your Travelway adventure."
    },
]