import Map from "components/Map";
import "./routes.scss";
const AppRoutes = () => {
  return (
    <div className="our-routes">
      <div className="intro">
        <p className="services">ROUTES</p>
        <p>
          Explore our extensive route network using our interactive map below.
        </p>
        <button className="get-the-app">GET THE APP</button>
      </div>
      <div className="map">
        <Map />
      </div>
      <div className="wrapper">
      <div className="book-your-ride">
        <p className="main">Unlock Your Intrastate Adventure</p>
        <p>
          Subscribe to a route with our loyalty program; the more you ride, the
          more you earn.
        </p>
        <button>BOOK YOUR RIDE NOW</button>
      </div>
      </div>
    </div>
  );
};
export default AppRoutes;
