
import Container from "components/container";
import TealHeader from "utils/TealHeader";
import OfferCardExclusive from "components/Offer";
import { OfferCard } from "components/Offer";
import "./pricing.scss";


const Pricing = () => {

    return (
        <Container> 
            <div className="pricing_range">
                <div><TealHeader size="sm"> PRICING </TealHeader></div>
                <h2>Select from a range of pricing plans designed to suit your unique needs</h2>

           <div className="offerTypes">
              <OfferCardExclusive tripType="TWO-WAY TRIP" amount="$1" />
              <OfferCard tripType="ONE-WAY TRIP" amount="$2" />
              <OfferCard tripType="TWO-WAY TRIP" amount="$1" />
            </div>
        </div>

        <div className="offers-desc">
            <div className="one-way">
                 <div><TealHeader size="sm"> ONE-WAY TRIP </TealHeader></div>
                 <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. 
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                    penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,
                    vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a,
                    venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. 
                    Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. 
                    Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae,
                    eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
                    Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. 
                    Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies n
                    </p>
            </div>

            <div className="round-trip">
                 <div><TealHeader size="sm"> ROUND TRIP </TealHeader></div>
                   <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. 
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                    penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,
                    vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a,
                    venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. 
                    Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. 
                    Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae,
                    eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
                    Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. 
                    Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies n
                    </p>
            </div>

        </div>

            <div className="subscribeToRoute">
                 <div><TealHeader size="sm"> SUBSCRIBE TO ROUTE </TealHeader></div>
                   <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. 
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque
                    penatibus et magnis dis parturient montes, nascetur ridiculus mus. 
                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                    Nulla consequat massa quis enim. Donec pede justo, fringilla vel, aliquet nec,
                    vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a,
                    venenatis vitae, justo. Nullam dictum felis eu pede mollis pretium. 
                    Integer tincidunt. Cras dapibus. Vivamus elementum semper nisi. 
                    Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae,
                    eleifend ac, enim. Aliquam lorem ante, dapibus in, viverra quis, feugiat a, tellus.
                    Phasellus viverra nulla ut metus varius laoreet. Quisque rutrum. Aenean imperdiet. 
                    Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies n
                    </p>
            </div>
        </Container>


    )
}
export default Pricing;