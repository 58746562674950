
import Styles from "./styles/card.module.scss";



const Card = ({...props}) => {


    const left = {background: "linear-gradient(332deg, #EB5E28 0.05%, #7A2200 21.05%, rgba(24, 24, 24, 0.00) 57.41%"}
    const right = {background: "linear-gradient(-332deg, #EB5E28 0.05%, #7A2200 21.05%, rgba(24, 24, 24, 0.00) 57.41%"}

    const colorPosition = props.position === "left" ? left : right;

    return (
           
        <div className={Styles.cardContainer} style={colorPosition} >

            {props.children}

        </div>
    );

}


export default Card;