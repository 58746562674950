import { useMemo } from "react";
import Styles from "./styles/processCard.module.scss";


const ProcessCard = ({...props}) => {

   
    const colorType = useMemo(() => {

        //  const blue = {boxShadow : "-2px 2px 4px 0px rgba(51, 127, 255, 0.29) inset, -2px -4px 4px 0px #121212 inset", width: "23%"};
        //  const orange = {boxShadow: "1px 2px 4px 0px rgba(235, 94, 40, 0.12) inset, 1px -4px 4px 0px #121212 inset", width: "30%"};

        if (props.type === "orange") return Styles.orangeCard
        if (props.type === "blue") return Styles.blueCard;
    }, [props.type])

    return (
        <div className={colorType}>{props.children}</div>
    )
}


export default ProcessCard;