import Assets from "utils/imagesUpload";
import "./styles/FaqAccordion.scss";
import { useState } from "react";

const FaqAccordion = ({...props}) => {

    const [isActive, SetIsActive] = useState(false);

    return (
       
    <>
            <div id={props.id} className="accordion-container" onClick={() => SetIsActive(!isActive)}>
                
            <p>{props.title}</p>
                {
                    isActive ?
                     <img src={Assets.BackwardIcon} alt="backward-icon" className="img-up" />  :
                    <img src={Assets.BackwardIcon} alt="backward-icon" className="img-down" />     
                }
                
            </div>

            { isActive && <div className="accordion-content"> {props.content} </div>}
</>
        
               
        
    )
}

export default FaqAccordion;