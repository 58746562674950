import React from 'react';
import { MapContainer, TileLayer, Marker, Popup, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './styles/map.scss';
import icon from '../assets/svg/ride.svg';

const Map = () => {
  const center = [6.5244, 3.3792];
  const zoom = 12;
  const customIcon = new L.Icon({
    iconUrl: icon,
    iconSize: [60, 60],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
  });
  const locations = [
    { position: [6.5392, 3.3842], popupContent: 'SHOMOLU' },
    { position: [6.5054, 3.3434], popupContent: 'YABA' },
    { position: [6.4926, 3.3490], popupContent: 'SURULERE' },
    { position: [6.6018, 3.3515], popupContent: 'IKEJA' }, 
    { position: [6.4500, 3.4333], popupContent: 'IKOYI' }, 
  ];

  return (
    <div className="map-container">
      <MapContainer center={center} zoom={zoom} style={{ height: '100%', width: '100%', backgroundColor: '#f0f0f0' }} zoomControl={false} attributionControl={false}>
        <TileLayer
          url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
        />
        {locations.map((location, index) => (
          <Marker key={index} position={location.position} icon={customIcon}>
            <Popup>
              <div className="custom-popup">
                <p>{location.popupContent}</p>
              </div>
            </Popup>
          </Marker>
        ))}
        <ZoomControl position="bottomleft" />
      </MapContainer>
    </div>
  );
};

export default Map;
