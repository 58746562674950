import "./about.scss";
import images from "../../utils/imagesUpload";
// import people from "./data.json"
import DoubleCard from "components/DoubleCard";
import DoubleCardReverse from "components/DoubleCardReverse";

const About = () => {
  return (
    <div className="about-us-page">
      <div className="about-us">
        <p className="about">ABOUT US</p>
        <p className="p1">
          <span>We are a</span> Mobility as a Service (MaaS){" "}
          <span>company with </span>
          cutting edge technology
          <span> to </span> revolutionize <span>how people commute in</span>{" "}
          urban areas
        </p>
        <p className="p2">
          Our mission is to provide efficient, affordable and sustainable
          transportation solutions that enhance mobility and improve commuter's
          overall quality of life
        </p>
      </div>
      {/* <div className="meet-the-team">    
                <button className="meet">MEET THE TEAM</button>
                <p className="p3">Meet the dedicated individuals who work tirelessly to ensure your journey 
                    is seamless
                </p>
            </div> */}
            <div className="double-cards">
      <DoubleCard
        img1={images.Contact}
        firstText="MISSION AND VISION"
        longText="Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem iosum
                ipsum ipsum ipsum ipsum lorem lorem ipsum lorem ipsum lorem ipsum lorem ipsum
                lorem ipsum lorem ipsum lorem ipsum rem ipsum lorem ipsum"
        btext="START YOUR JOURNEY"
        img2={images.AboutRoad}
      />
      <DoubleCardReverse
        img1={images.Contact}
        firstText="STORY"
        longText="We are a Mobility as a Service (MaaS) Company with
          cutting-edge technology to revolutionize how people commute in urban
          areas. Our mission is to provide efficient, affordable, and sustainable
          transportation solutions that enhance mobility and improve commuters'
          overall quality of life."
        btext="START YOUR JOURNEY"
        img2={images.Books}
      />
      </div>

      {/* <div className="team">
                <div className="team-header">
                    <button className="">OUR TEAM</button>
                    <p>Meet the amazing team</p>
                </div>
                <div className="cards">
                {people.map((person) => {
                    return (
                        <div className="card">
                            <div className="inner">
                                <p>{person.name}</p>
                                <p className="sub">{person.role}</p>
                                <p className="sub">{person.skill}</p>
                                <a href={person.linkedIn} target="_">
                                    <img src={images.Linkedin} alt="" className="linkedin"/>
                                </a>
                            </div>
                            <div className="card-image">
                                <img src={images.Person} alt=""/>
                            </div>
                        </div>
                    )
                })}
                </div>
            </div> */}
    </div>
  );
};
export default About;
