import React from "react";
import "./styles/DoubleCard.scss"
const DoubleCard = (props) => {
  return (
    <div className="mission-and-vision">
      <div className="mission">
        <div className="small">
          <img src={props.img1} alt="" />
          <p className="p-small">{props.firstText}</p>
        </div>
        <p className="desc">{props.longText}</p>
        <button>{props.btext}</button>
      </div>
      <div className="image">
        <img src={props.img2} alt="" />
      </div>
    </div>
  );
};

export default DoubleCard;
