import Homepage from "pages/home/homePage";
import About from "pages/about/about";
import Services from "pages/services/services";
import AppRoutes from "pages/routes/routes";
import Pricing from "pages/pricing/pricing";
import Contact from "pages/contact/contact";

import { Route, Routes } from "react-router-dom";
import Header from "components/header";
import Footer from "components/footer";
import Container from "components/container";


function App() {
  return (
   
    <Container>
<>
        <Header />

        <Routes>
          <Route exact element={<Homepage />} path="/" />
          <Route exact element={<About />} path="/about" />
          <Route exact element={<Services />} path="/services" />
          <Route exact element={<AppRoutes />} path="/routes" />
          <Route exact element={<Pricing />} path="/pricing" />
          <Route exact element={<Contact />} path="/contact" />
        </Routes>
        <Footer />
        </>
    </Container>
  
  );
}

export default App;

// export default App;
// import React from "react";
// import { Route, Routes } from "react-router-dom";

// import Homepage from "pages/home/homePage";
// import About from "pages/about/about";
// import Services from "pages/services/services";
// import AppRoutes from "pages/routes/routes";
// import Pricing from "pages/pricing/pricing";
// import Contact from "pages/contact/contact";
// import Header from "components/header";

// function App() {
//   return (
//     <div>
//       {/* Header is always rendered */}
//       <Header />

//       <Routes>
//         {/* Nested routes */}
//         <Route path="/" element={<Homepage />} />
//         <Route path="/about" element={<About />} />
//         <Route path="/services" element={<Services />} />
//         <Route path="/routes" element={<AppRoutes />} />
//         <Route path="/pricing" element={<Pricing />} />
//         <Route path="/contact" element={<Contact />} />
//       </Routes>
//     </div>
//   );
// }

// export default App;
