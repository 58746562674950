import Assets from "./imagesUpload";
import "./avatarProfile.scss";

const AvatarProfiles = ({...props}) => {

    return (
        <div className="avatar-profiles">
            <div className="avatar-container">
                <img src={Assets.Avatar} alt="avatar-images" />
            </div>
                <span className="name">{props.name}</span>
                <span className="title">{props.title}</span>
        </div>
        
    )
}

export default AvatarProfiles;