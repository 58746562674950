import Assets from "utils/imagesUpload";
import "./styles/header.scss";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { useState } from "react";



const Header = ({...props}) => {

    const [isOpen, setIsOpen] = useState(false);

    const displayMenu = () => {
            setIsOpen(!isOpen)
    }


    return (
        <>
        <div className="header-container">
            <div> 
                <Link to={"/"}> 
                     <img src={Assets.TravelWayLogo} alt="travelway_logo" width="100rem" />
                </Link>
            </div>

            <div className="nav">
                <ul>
                  <Link to={"/about"}><li>ABOUT US</li></Link>
                  <Link to={"/services"}> <li>SERVICES</li></Link>
                  <Link to={"/routes"}> <li>ROUTES</li></Link>
                  <Link to={"/pricing"}> <li>PRICING</li></Link>
                  <Link to={"/contact"}> <li>CONTACT US</li></Link>
                </ul>
            </div>

            <div className="hamburger" onClick={displayMenu} ><GiHamburgerMenu /></div>

        </div>
                {isOpen && <div className="menu-bar">
                  <ul>
                  <Link to={"/about"}><li>ABOUT US</li></Link>
                  <Link to={"/services"}> <li>SERVICES</li></Link>
                  <Link to={"/routes"}> <li>ROUTES</li></Link>
                  <Link to={"/pricing"}> <li>PRICING</li></Link>
                  <Link to={"/contact"}> <li>CONTACT US</li></Link>
                </ul>
                </div>}
        </>
    )
}
export default Header;
