import ProcessCard from "components/ProcessCard";
import Assets from "utils/imagesUpload";
import TealHeader from "utils/TealHeader";
import AppButtons from "utils/AppButtons";
import "./contact.scss";

const Contact = () => {

    return (
        <>
            <div className="contact"> 
                   <div><TealHeader size="sm"> CONTACT US </TealHeader></div>
           <div> Contact us anytime for questions, assistance, or feedback</div>
            </div>

            <div className="contact-cards">

             <ProcessCard type="orange">
                        <div className="processCard-content_contact">
                            <img src={Assets.VisitIcon} alt="visit_icon" />
                            <div>Visit us</div>
                            <div>Meet us at our office</div>
                            <div>Suite A10, Ozimba Mbaduwe, Lekki phase 1, Lagos, Nigeria</div>
                        </div>
             </ProcessCard>

             
             <ProcessCard type="orange">
                        <div className="processCard-content_contact">
                            <img src={Assets.MailIcon} alt="visit_icon" />
                            <div>Email us</div>
                            <div>We're here to help</div>
                            <div>contact@travelway.com</div>
                        </div>
             </ProcessCard>


             
             <ProcessCard type="orange">
                        <div className="processCard-content_contact">
                            <img src={Assets.VisitIcon} alt="visit_icon" />
                            <div>Chat with us</div>
                            <div>Mon-Fri from 8am to 6pm</div>
                            <div>+234 812 345 6789</div>
                        </div>
             </ProcessCard>
            </div>

            <div className="contact-forms">

                <h4>Use our convenient for below to contact us directly </h4>

                <form>

                <div className="form-fields">

                    <div className="name-email">
                        <div className="name-email_width">
                            <div className="boxName">name</div>
                            <input />
                        </div>

                        <div className="name-email_width">
                            <div className="boxName">email</div>
                            <input />
                        </div>

                    </div>

                    <div className="message">
                         <div className="boxName">message</div>
                        <textarea></textarea>
                    </div>
                    
                <AppButtons size="lg">SEND</AppButtons>
                </div>

                </form>

            </div>
            </>
        // </Container>

    )
}
export default Contact;