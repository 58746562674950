import { useMemo } from "react";
import  styles from "./AppButtons.module.scss";

const AppButtons = ({...props}) => {


    const buttonSizes = useMemo(() => {    
        if (props.size === "sm") return styles.sm
        if (props.size === "md") return styles.md
        if (props.size === "lg") return styles.lg
    }  , [props.size]
    )


    return (
        <button className={buttonSizes}>{props.children}</button>
    )

}


export default AppButtons;