
import "./styles/footer.scss";
import Assets from "utils/imagesUpload";
import { Link } from "react-router-dom";

const Footer = () => {
    const currentYear = new Date().getFullYear()

    return (
        
        <div className="footer">
        <hr className="line" />
            <div className="quickly-navigate">Quickly navigate our website using the footer links for a hassle-free experience</div>
            <div className="footer-navs">

                <div className="navs">
                    <h3>NAVIGATION</h3>
                    <ul>
                        <Link to={"/about"} className="link"><li>About us</li></Link>
                        <Link to={"/services"} className="link"><li>Services</li></Link>
                        <Link to={"/routes"} className="link"><li>Routes</li></Link>
                        <Link to={"/pricing"} className="link"><li>Pricing</li></Link>
                        <Link to={"/contact"} className="link"><li>Contact Us</li></Link>
                    </ul>
                </div>
                <div className="partners">
                    <h3>PARTNERS</h3>
                    <ul>
                        <li>Lagos State Govt.</li>
                        <li>Access Bank Plc.</li>
                    </ul>
                </div>
                <div className="resource">
                    <h3>RESOURCES</h3>
                    <ul>
                        <li>Resources</li>
                        <Link to={"/#faq-section"} className="link"><li>FAQ</li></Link>
                    </ul>
                </div>

              <div className="logos">
             <div><img src={Assets.TravelWayLogo} alt="travelway_logo" className="travelway-logo" /></div>
                    <div className="socials">
                        <img src={Assets.YoutubeLogo} alt="youtube_logo"  />
                        <img src={Assets.FacebookLogo} alt="facebook_logo" />
                        <img src={Assets.InstagramLogo} alt="instagram_logo" />
                        <img src={Assets.TwitterLogo} alt="twitter_logo" />
                    </div>
                    <h3>contact@travelway.com</h3>
                </div>
            </div>
             <hr className="line" />
             <div>
                <p><span className="copyright">&copy;</span> TravelWay, {currentYear}</p>
             </div>
        </div>


    )
}

export default Footer;