import { useMemo } from "react";
import styles from  "./tealHeader.module.scss";


const TealHeader = ({...props}) => {

   const divSizes =  useMemo(() => {

        if (props.size === "sm") return styles.tealHeader_sm;
        if (props.size === "md") return styles.tealHeader_md;
        if (props.size === "lg") return styles.tealHeader_lg;

    }, [props.size])

    return (
        <div className={divSizes}>
            {props.children}
        </div>
    )
}

export default TealHeader;