    import DoublePhone from "assets/images/double_phone.png";
    import Laptop from ".././assets/images/laptop_screen.png";
    import Road from ".././assets/images/open_road.png";
    import TravelWayLogo from ".././assets/images/travelwayLogo_white.png";
    import Contact from ".././assets/images/contact.svg";
    import Linkedin from ".././assets/images/linkedin.svg";
    import AboutRoad from ".././assets/images/road.png";
    import Books from ".././assets/images/books.png";
    import Person from ".././assets/images/person.png"
    import Clock from "../assets/images/clock.svg"
    import Club from "../assets/images/club.png"
    import Driver from "../assets/images/driver.svg";
    import Gprs from "../assets/images/gprs.svg";
    import Pricing from "../assets/images/pricing.svg";
    import Wheel from "../assets/images/wheel.png";
    import Wifi from "../assets/images/wifi.svg";
    import Meter from "../assets/images/meter.png";
    import Simple from "../assets/images/simple.png";
    import Teddy from "../assets/images/teddy.png";
    import UserOctagon from "../assets/images/useroctagon.svg";
    import Phone from "../assets/images/phone.png";
    import Avatar from "../assets/images/avatar.jpg";
    import BackwardIcon from "assets/svg/backward.svg";
    import ForwardIcon from "assets/svg/forward.svg";
    import Checkbox from "assets/svg/checkbox.svg";
    import BottomArrow from "assets/svg/bottomArrow.svg";
    import InstagramLogo from "assets/svg/instagram_logo.svg";
    import TwitterLogo from "assets/svg/twitter_logo.svg"
    import FacebookLogo from "assets/svg/facebook_logo.svg";
    import YoutubeLogo from "assets/svg/youtube_logo.svg";
    import VisitIcon from "assets/svg/visit_icon.svg";
    import MailIcon from "assets/svg/mail_icon.svg";
    import WhatsAppIcon from "assets/svg/whatsApp_icon.svg";


    const Assets =  {
            DoublePhone,Laptop,Road,
            TravelWayLogo,Contact,Linkedin,
            AboutRoad,Books,Person,Clock,
            Club,Driver,Gprs,Pricing,Wheel,Wifi,
            Meter,Simple,Teddy,UserOctagon,Phone,Avatar,
            BackwardIcon, ForwardIcon, Checkbox,
            BottomArrow,InstagramLogo,FacebookLogo, TwitterLogo,
            YoutubeLogo, VisitIcon, MailIcon, WhatsAppIcon
            }
        
    
   

    export default Assets;