import Card from "components/Card";
import Container from "components/container";
import AppButtons from "utils/AppButtons";
import "./home.scss";
import images from "utils/imagesUpload";
import { IoArrowForwardCircle } from "react-icons/io5";
import TealHeader from "utils/TealHeader";
import ProcessCard from "components/ProcessCard";
import AvatarProfiles from "utils/avatarProfile";
import Assets from "utils/imagesUpload";
import OfferCardExclusive, { OfferCard } from "components/Offer";
import FaqAccordion from "components/FaqAccordion";
import { accordionData } from "utils/accordionData";
import { Link } from "react-router-dom";



const Homepage = ()  => {


       
    return (
        <>
            <div className="homepageCard_container">

                <Card position = "left">
                   <p className="card-label">BOOK NOW, SAVE NOW</p>
                   <p>Book, Ride, Repeat</p>
                   <p className="desc">Our user-friendly app and website make booking a breeze</p>
                   <AppButtons size="sm">Get the App</AppButtons>
                   <div className="img-container_laptop">
                   <img src={images.Laptop} alt="laptop_mockup" className="laptop-image" />
                   </div>
                </Card>

                <Card position = "right">
                   <p className="card-label">SEAMLESS SAVINGS, SAVE NOW</p>
                    <p>Our user-friendly app and website make booking a breeze</p>
                   <p className="desc">Choose your route, select your seat, and get ready for a hassle-free journey.</p>
                   <AppButtons size="sm">Get the App</AppButtons>
                   <div className="img-container_phone">
                   <img src={images.DoublePhone} alt="phone_mockup" className="phone-image" />
                   </div>
                </Card>

            </div>

            <div className="welcome-card">
                
                <div className="card_section-upper">
                <p>YOUR JOURNEY, OUR DESTINATION</p>
                <p>Welcome to TravelWay</p>
                <p>Discover a new way to travel within Lagos community, comfortably and sustainably, with our modern bus services. 
                Experience the freedom of seamless intrastate travel with us.</p>
                </div>

                <div className="card_section-lower">
                    <div className="inner-left">
                        <p>Join us in shaping the future of transportation</p>
                        <p>Embrace eco friendly, hassle-free, community-driven transporation with travelway</p>
                    <button>GET STARTED <span><IoArrowForwardCircle style={{color : "#eb5e28"}} /></span> </button>
                    </div>

                    <div className="inner-right">
                        <img src={images.Road} alt="open_road_img" />
                    </div>
                </div>
            </div>

            <div className="how_works-section">
                <div><TealHeader size="sm">HOW IT WORKS</TealHeader></div>
                <div className="quick-journey">A quick and easy journey <br /> <span>with us</span> </div>
                <div className="processes">
                    <ProcessCard type="blue">
                        <div className="processCard-content">
                            <div>1</div>
                            <div>Sign Up</div>
                            <div>Ready for a stress-free journey? Get started in seconds! 
                                <Link to="/signUp">Sign up now </Link>, and let's make your commute a breeze</div>
                        </div>
                    </ProcessCard>

                     <ProcessCard type ="blue">
                        <div className="processCard-content">
                            <div>2</div>
                            <div>Log into dashboard</div>
                            <div>Your journey begins with a <Link to="/signUp"> click.</Link> Log in to our dashboard it's your command center for easy, efficient travel.
                            </div>
                        </div>
                    </ProcessCard>

                     <ProcessCard type="blue">
                        <div className="processCard-content">
                            <div>3</div>
                            <div>Choose a Route</div>
                            <div>Pick your path effortlessly! Swipe through routes, check real-time updates, and choose what fits your schedule. It's that simple.
                            </div>
                        </div>
                    </ProcessCard>

                     <ProcessCard type="blue">
                        <div className="processCard-content">
                            <div>4</div>
                            <div>Make payment</div>
                            <div>Time to seal the deal. Our secure payment process is quick and transparent. No surprises—just smooth transactions</div>
                        </div>
                    </ProcessCard>
            
                </div>
            </div>

            <div className="customer-container">

                <div><TealHeader size="sm">CUSTOMERS LOVE IT!</TealHeader></div>
              
                <div>
                    Used by a students, employers, employees, and numerous individuals
                    representing companies companies across diverse industries nationwide
                </div>

                <div className="images">
                    
                    <AvatarProfiles 
                    name = "Tonia Adeboyega" 
                    title="STUDENT" 
                     />
                      <AvatarProfiles 
                    name = "Tonia Adeboyega" 
                    title="STUDENT" 
                     />
                      <AvatarProfiles 
                    name = "Tonia Adeboyega" 
                    title="STUDENT" 
                     />
                    
                </div>

                <div className="content">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit Aenean commodo 
                    ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis
                    dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec,
                    pellentesque eu, pretium quis, sem.
                </div>

                <div className="arrows">
                    <img src={Assets.BackwardIcon} alt="backward-icon" />
                    <img src={Assets.ForwardIcon} alt="forward-icon" />
                </div>
            </div>

            <div className="offerTypes">
              <OfferCardExclusive tripType="TWO-WAY TRIP" amount="$1" />
              <OfferCard tripType="ONE-WAY TRIP" amount="$2" />
              <OfferCard tripType="TWO-WAY TRIP" amount="$1" />
            </div>

            <div className="faqs" id="faq-section">
                <div><TealHeader size="sm"> FAQs </TealHeader></div>
                <div>
                    The answers to the most popular frequent questions
                </div>
                <div>
                    If you can't find what you're looking for, contact our support team and
                    we'll get back to you
                </div>

                {accordionData.map( ({title, content}) => (
                   
                     <FaqAccordion title={title} content = {content}/>
        
                ))}               

            </div>

            <div className="intrastate-adventure">
                <div>Unlock your intrastate<br /> adventure</div>
                <div>Subscribe to a route with our loyalty program, the more you ride, the more you earn</div>
                <span>Book your ride now</span>
            </div>
         </> 

    )
}


export default Homepage; 