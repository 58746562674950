import DoubleCard from "components/DoubleCard";
import "./services.scss";
import images from "../../utils/imagesUpload";
import PriceComponent from "components/Pricing";
import DoubleCardReverse from "components/DoubleCardReverse";
import OfferCardExclusive, { OfferCard } from "components/Offer";


const Services = () => {
  return (
    <div className="our-services">
      <div className="intro">
        <p className="services">SERVICES</p>
        <p>
          Our buses offer plush, spacious seats designed for your comfort during
          every journey.
        </p>
        <button className="get-the-app">GET THE APP</button>
      </div>
      <div className="double-cards">
        <DoubleCard
          img1={images.Wifi}
          firstText="WIFI AND CHARGING PORTS"
          longText="Stay connected with complimentary Wi-Fi and
                keep your devices charged on board."
          btext="START YOUR JOURNEY"
          img2={images.Phone}
        />
        <DoubleCardReverse
          img1={images.Gprs}
          firstText="Real-time GPS Tracking"
          longText="Track your bus in real-time and get live updates
                on your travel progress."
          btext="START YOUR JOURNEY"
          img2={images.Wheel}
        />
        <DoubleCard
          img1={images.Pricing}
          firstText="Affordable Pricing"
          longText="Enjoy the best fares in town without compromising
                service quality."
          btext="START YOUR JOURNEY"
          img2={images.Teddy}
        />
        <DoubleCardReverse
          img1={images.Contact}
          firstText="Easy Booking"
          longText="Booking a ride is a breeze with our intuitive app and
                website."
          btext="START YOUR JOURNEY"
          img2={images.Simple}
        />
        <DoubleCard
          img1={images.Driver}
          firstText="Professional Drivers"
          longText="Our experienced, courteous drivers prioritize your
                safety and comfort."
          btext="START YOUR JOURNEY"
          img2={images.Meter}
        />
        <DoubleCardReverse
          img1={images.Clock}
          firstText="24/7 Availability"
          longText="Travel anytime, day or night, with our round-the-clock
                service."
          btext="START YOUR JOURNEY"
          img2={images.Club}
        />
      </div>
      <div className="intro">
        <button className="services-second">TRAVELWAY RIDE OPTIONS</button>
        <p>
          Our buses offer plush, spacious seats designed for your comfort during
          every journey.
        </p>
        <button className="get-the-app">GET THE APP</button>
      </div>
      <div className="offerTypes">
              <OfferCardExclusive tripType="TWO-WAY TRIP" amount="$1" />
              <OfferCard tripType="ONE-WAY TRIP" amount="$2" />
              <OfferCard tripType="TWO-WAY TRIP" amount="$1" />
            </div>
      <div className="book-your-ride">
        <p className="main">Unlock Your Intrastate Adventure</p>
        <p>
          Subscribe to a route with our loyalty program; the more you ride, the
          more you earn.
        </p>
        <button>BOOK YOUR RIDE NOW</button>
      </div>
    </div>
  );
};
export default Services;
