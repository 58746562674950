
import Assets from "utils/imagesUpload";
import "./styles/offer.scss";


const OfferCardExclusive = () => {


    return (
       <div className="offer_exclusive">

            <div className="title">Exclusive Offer</div>
            <p className="title-content">
                Ready to embark on your next movement? Book now and enjoy
                an exclusive 15% discount on your first ride with us. Don't miss out!
            </p>

            <p>120 spots left</p>

            <div>
                <hr />
                <div className="bottom-content">Limited-Time Offer: Get 15% Off Your First Booking</div>
            </div>


       </div>
    )
}


export default OfferCardExclusive;


export const OfferCard = ({...props}) => {

    return (
        <div className="offer">
            <div className="kilometre"> <span>{props.amount}</span> /kilometre </div>
            <div className="title">{props.tripType}</div>
            <div className="trip-benefits">
                <ul>
                    <li>
                        <p>
                        <img src={Assets.Checkbox} alt="checkbox_icon" />
                       <span> Comfortable Seating: Our buses offer plush, spacious seats designed for
                        your comfort during every journey.</span>
                        </p>
                    </li>

                    <li>
                        <p>
                        <img src={Assets.Checkbox} alt="checkbox_icon" className="checkbox" />
                        {/* <object type="image/svg+xml" className="checking" data={Assets.Checkbox}>Your browser cannot display this image</object> */}
                        <span>Wi-Fi and Charging Ports</span>
                        </p>
                    </li>

                    <li>
                        <p>
                        <img src={Assets.Checkbox} alt="checkbox_icon" />
                        <span>Real-time GPS Tracking</span>
                        </p>
                    </li>

                    <li>
                        <p>
                        <img src={Assets.Checkbox} alt="checkbox_icon" />
                        <span>Affordable Pricing</span>
                        </p>
                    </li>

                    <li>
                        <p>
                        <img src={Assets.Checkbox} alt="checkbox_icon" />
                        <span>Easy Booking </span>  
                        </p> 
                    </li>

                    <li>
                        <p>
                        <img src={Assets.Checkbox} alt="checkbox_icon" />
                        <span>Professional Drivers</span>
                        </p>
                    </li>
                </ul>
            </div>

        <p></p>
        <hr />
        <p></p>

            <div className="get-started">
                GET STARTED
            </div>
        </div>
    )
}
